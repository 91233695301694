import { type FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { isUpdatedMoreThan15HoursAgo } from "scmp-app/components/plus/news-agenda/helpers";
import { useNewsAgenda } from "scmp-app/components/plus/news-agenda/hooks";
import { UpdatedLabel } from "scmp-app/components/plus/news-agenda/updated-label";
import type { contentAgendaContentTopic$key } from "scmp-app/queries/__generated__/contentAgendaContentTopic.graphql";

import {
  Container,
  DateContainer,
  DayContainer,
  EventDescription,
  LeftContainer,
  LocationContainer,
  MiddleContainer,
  MonthContainer,
  RightContainer,
  StyledArrowForwardIcon,
  TitleContainer,
  UpdatedLabelContainer,
} from "./styles";

type Props = {
  className?: string;
  isFirstKeyEventAgenda?: boolean;
  needToShowUpdatedLabel?: boolean;
  reference: contentAgendaContentTopic$key;
  showAdditionalContent?: boolean;
};

export const AgendaContent: FunctionComponent<Props> = ({
  className,
  isFirstKeyEventAgenda,
  needToShowUpdatedLabel,
  reference,
  showAdditionalContent,
}) => {
  const data = useFragment(
    graphql`
      fragment contentAgendaContentTopic on Topic {
        newsAgenda {
          secondaryDescription
        }
        description {
          text
        }
        updatedDate
        name
        ...hooksNewsAgendaTopic
      }
    `,
    reference,
  );

  const { date, day, hasDetailsView, isKeyEvent, isPast, month, time } = useNewsAgenda(data);

  const isUpdated = isUpdatedMoreThan15HoursAgo(data.updatedDate);

  return (
    <Container $clickable={hasDetailsView} $isPast={isPast} className={className}>
      <LeftContainer>
        <DayContainer $isKeyEvent={isKeyEvent}>{day}</DayContainer>
        <MonthContainer $isKeyEvent={isKeyEvent}>{month}</MonthContainer>
      </LeftContainer>
      <MiddleContainer>
        <TitleContainer>{data.name}</TitleContainer>
        {needToShowUpdatedLabel && isUpdated && (
          <UpdatedLabelContainer>
            <UpdatedLabel />
          </UpdatedLabelContainer>
        )}
      </MiddleContainer>
      <RightContainer>
        {showAdditionalContent && (
          <>
            <DateContainer>
              {date} <span>{time}</span>
            </DateContainer>
            <LocationContainer>{data.newsAgenda?.secondaryDescription}</LocationContainer>
          </>
        )}
      </RightContainer>
      {hasDetailsView && <StyledArrowForwardIcon />}
      {isFirstKeyEventAgenda && data.description && (
        <EventDescription>{data.description?.text}</EventDescription>
      )}
    </Container>
  );
};

AgendaContent.displayName = "AgendaContent";
