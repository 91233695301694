/**
 * @generated SignedSource<<c2b7923168faa701d706adcb913b06fb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type hooksArticleDateUtilsContent$data = {
  readonly createdDate: number | null | undefined;
  readonly publishedDate: number | null | undefined;
  readonly updatedDate: number | null | undefined;
  readonly " $fragmentType": "hooksArticleDateUtilsContent";
};
export type hooksArticleDateUtilsContent$key = {
  readonly " $data"?: hooksArticleDateUtilsContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"hooksArticleDateUtilsContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "hooksArticleDateUtilsContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publishedDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedDate",
      "storageKey": null
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};

(node as any).hash = "495bec072091c50a24ee26ef3c850a6d";

export default node;
