/**
 * @generated SignedSource<<0d8879d1a7456814b23f59087a746790>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type hooksNewsAgendaTopic$data = {
  readonly newsAgenda: {
    readonly endDate: number | null | undefined;
    readonly newsAgendaOptions: ReadonlyArray<string>;
    readonly startDate: number | null | undefined;
    readonly time: number | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "hooksNewsAgendaTopic";
};
export type hooksNewsAgendaTopic$key = {
  readonly " $data"?: hooksNewsAgendaTopic$data;
  readonly " $fragmentSpreads": FragmentRefs<"hooksNewsAgendaTopic">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "hooksNewsAgendaTopic",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "NewsAgenda",
      "kind": "LinkedField",
      "name": "newsAgenda",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "time",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "newsAgendaOptions",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Topic",
  "abstractKey": null
};

(node as any).hash = "e756e74e928242964969d52a7534a98d";

export default node;
