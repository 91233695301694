import styled from "@emotion/styled";
import { fontRobotoCondensed } from "@product/scmp-sdk";

export const LabelContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  inline-size: 55px;
  block-size: 20px;
  padding-block: 2px;
  padding-inline: 4px;

  color: #ffffff;
  font-weight: 700;
  font-size: 12px;
  font-family: ${fontRobotoCondensed};
  text-transform: uppercase;

  border-radius: 2px;

  background-color: #4585ff;
`;
