import { graphql } from "react-relay";
import { readInlineData } from "relay-runtime";

import type { helpersCheckIsNewsAgendaTopicTopic$key } from "scmp-app/queries/__generated__/helpersCheckIsNewsAgendaTopicTopic.graphql";

export const parseTime = (time?: null | number | undefined) => {
  if (!time) return [];
  const hour = Math.floor(time / 3600);
  const minute = Math.floor((time % 3600) / 60);
  const addedPrefixZeroMinute = minute < 10 ? `0${minute}` : minute;
  return [hour, addedPrefixZeroMinute];
};

export const isUpdatedMoreThan15HoursAgo = (updatedDate: null | number | undefined): boolean => {
  if (!updatedDate) return false;

  const now = Date.now();

  const fifteenHoursAgo = now - 15 * 60 * 60 * 1000;

  return updatedDate > fifteenHoursAgo;
};

export const checkIsNewsAgendaTopic = (reference: helpersCheckIsNewsAgendaTopicTopic$key) => {
  const topic = readInlineData(
    graphql`
      fragment helpersCheckIsNewsAgendaTopicTopic on Topic @inline {
        topicTypes: types
      }
    `,
    reference,
  );

  return topic.topicTypes?.includes?.("news_agenda");
};
