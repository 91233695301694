import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { fontRobotoCondensed, transientOptions } from "@product/scmp-sdk";

import { DatePicker } from "scmp-app/components/plus/date-picker";

export const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  block-size: 100%;

  column-gap: 16px;
`;

export const StyledCheckbox = styled(Checkbox)`
  margin-inline-end: 8px;
  padding: 0;
`;

type StyledFormControlLabelProps = {
  $isShow?: boolean;
};
export const StyledFormControlLabel = styled(FormControlLabel, {
  ...transientOptions,
})<StyledFormControlLabelProps>`
  margin-inline-end: 0;
  ${props =>
    !props.$isShow &&
    css`
      display: none;
    `}
`;

export const StyledLabel = styled.label`
  color: #000000;
  font-weight: 400;
  font-size: 14px;
  font-family: ${fontRobotoCondensed};

  pointer-events: none;
`;

type StyledDatePickerProps = {
  $isShow?: boolean;
};

export const StyledDatePicker = styled(DatePicker, { ...transientOptions })<StyledDatePickerProps>`
  ${props =>
    !props.$isShow &&
    css`
      display: none;
    `}
`;
