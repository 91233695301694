/**
 * @generated SignedSource<<a19eaf9033a9429b25de3814eadd99a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type itemAgendaItemTopic$data = {
  readonly entityId: string;
  readonly name: string;
  readonly newsAgenda: {
    readonly startDate: number | null | undefined;
  } | null | undefined;
  readonly urlAlias: string;
  readonly " $fragmentSpreads": FragmentRefs<"contentAgendaContentTopic" | "hooksNewsAgendaTopic">;
  readonly " $fragmentType": "itemAgendaItemTopic";
};
export type itemAgendaItemTopic$key = {
  readonly " $data"?: itemAgendaItemTopic$data;
  readonly " $fragmentSpreads": FragmentRefs<"itemAgendaItemTopic">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "itemAgendaItemTopic",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "urlAlias",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "NewsAgenda",
      "kind": "LinkedField",
      "name": "newsAgenda",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startDate",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "contentAgendaContentTopic"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "hooksNewsAgendaTopic"
    }
  ],
  "type": "Topic",
  "abstractKey": null
};

(node as any).hash = "783e0938f83c18f6ec6e699f0e833f7a";

export default node;
