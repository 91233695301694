import { formatInTimeZone } from "@product/scmp-sdk";
import { endOfDay, isBefore, isSameDay } from "date-fns";
import { useCallback, useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { formatPlusDatePair } from "scmp-app/components/plus/helpers";
import { parseTime } from "scmp-app/components/plus/news-agenda/helpers";
import type { hooksNewsAgendaTopic$key } from "scmp-app/queries/__generated__/hooksNewsAgendaTopic.graphql";

export const useNewsAgenda = (reference: hooksNewsAgendaTopic$key) => {
  const data = useFragment(
    graphql`
      fragment hooksNewsAgendaTopic on Topic {
        newsAgenda {
          startDate
          endDate
          time
          newsAgendaOptions
        }
      }
    `,
    reference,
  );

  const hasDetailsView = useMemo(
    () => !data.newsAgenda?.newsAgendaOptions?.includes("disable_detail_view"),
    [data.newsAgenda?.newsAgendaOptions],
  );

  const isKeyEvent = useMemo(
    () => data.newsAgenda?.newsAgendaOptions?.includes("key_event"),
    [data.newsAgenda?.newsAgendaOptions],
  );

  const isPast = useMemo(() => {
    if (!data.newsAgenda?.endDate) return false;

    const today = new Date();
    const endDate = new Date(data.newsAgenda.endDate);
    if (isSameDay(today, endDate)) return false;

    return isBefore(endDate, endOfDay(today));
  }, [data.newsAgenda?.endDate]);

  const renderDay = useCallback(() => {
    if (!data.newsAgenda?.startDate) return null;
    return formatInTimeZone(data.newsAgenda.startDate, "d");
  }, [data.newsAgenda?.startDate]);

  const renderMonth = useCallback(() => {
    if (!data.newsAgenda?.startDate) return null;
    return formatInTimeZone(data.newsAgenda.startDate, "MMM");
  }, [data.newsAgenda?.startDate]);

  const renderDate = useCallback(() => {
    const finalized = data.newsAgenda?.newsAgendaOptions.includes("finalized_date");
    if (!data.newsAgenda?.startDate || !data.newsAgenda?.endDate) return null;
    const dateRange = formatPlusDatePair(data.newsAgenda.startDate, data.newsAgenda.endDate);
    return finalized ? dateRange : `${dateRange} (TBC)`;
  }, [data.newsAgenda?.endDate, data.newsAgenda?.startDate, data.newsAgenda?.newsAgendaOptions]);

  const renderTime = useCallback(() => {
    if (!data.newsAgenda?.time) return null;
    const [hour, minute] = parseTime(data.newsAgenda?.time);
    return `${hour}:${minute} HKT`;
  }, [data.newsAgenda?.time]);

  return {
    date: renderDate(),
    day: renderDay(),
    hasDetailsView,
    isKeyEvent,
    isPast,
    month: renderMonth(),
    time: renderTime(),
  };
};
