import { notEmpty } from "@product/scmp-sdk";
import { useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { formatInTimeZone, toISOString } from "scmp-app/lib/utils";
import type { hooksArticleDateUtilsContent$key } from "scmp-app/queries/__generated__/hooksArticleDateUtilsContent.graphql";

export const useArticleDateUtils = (
  reference?: hooksArticleDateUtilsContent$key,
  dateFormat: string = "h:mmaaa, d MMM yyyy",
) => {
  const content = useFragment(
    graphql`
      fragment hooksArticleDateUtilsContent on Content {
        createdDate
        publishedDate
        updatedDate
      }
    `,
    reference ?? null,
  );

  const { createdDate, updatedDate } = content ?? {};
  const publishedDate = useMemo(
    () => (notEmpty(content?.publishedDate) ? content?.publishedDate : createdDate),
    [content?.publishedDate, createdDate],
  );
  const formattedPublishedDate = useMemo(
    () => publishedDate && formatInTimeZone(publishedDate, dateFormat),
    [dateFormat, publishedDate],
  );

  const lastUpdatedDate = useMemo(
    () => (updatedDate ? formatInTimeZone(updatedDate, dateFormat) : publishedDate),
    [dateFormat, publishedDate, updatedDate],
  );

  const isoPublishedDate = useMemo(
    () => (publishedDate ? toISOString(publishedDate) : undefined),
    [publishedDate],
  );
  const isoUpdatedDate = useMemo(
    () => (updatedDate ? toISOString(updatedDate) : isoPublishedDate),
    [isoPublishedDate, updatedDate],
  );

  return {
    isoPublishedDate,
    isoUpdatedDate,
    lastUpdatedDate,
    publishedDate: formattedPublishedDate,
  };
};
