import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontRoboto, fontRobotoCondensed } from "@product/scmp-sdk";

import ArrowForwardIcon from "./icon-right.svg";

export const TitleContainer = styled.div`
  grid-area: title;

  padding-inline-end: 12px;

  color: #000000;
  font-weight: 700;
  font-size: 20px;
  font-family: ${fontRobotoCondensed};
  line-height: 23px;
`;

type ContainerProps = {
  $clickable?: boolean;
  $isPast?: boolean;
};

export const Container = styled.div<ContainerProps>`
  display: grid;
  grid:
    "left title right link" min-content
    / 36px 1fr 1fr 21px;

  padding: 16px;

  column-gap: 12px;

  border-radius: 8px;

  &:hover ${TitleContainer} {
    text-decoration: ${props => (props.$clickable ? "underline" : "none")};
  }

  ${props => {
    if (!props.$isPast) {
      return css`
        background-color: ${props.$clickable ? "#ffffff" : "rgba(255,255,255,.2)"};
      `;
    }
    return css`
      background-color: ${props.$clickable ? "#d4d4cb" : "rgba(212, 212, 203, .2)"};
    `;
  }}
`;

export const LeftContainer = styled.div`
  grid-area: left;
`;

type DayContainerProps = {
  $isKeyEvent?: boolean;
};

export const MiddleContainer = styled.div`
  border-inline-end: 1px solid rgba(0, 0, 0, 0.2);
`;

export const DayContainer = styled.div<DayContainerProps>`
  color: ${props => (props.$isKeyEvent ? "#4585FF" : "#000000")};
  font-weight: 400;
  font-size: 24px;
  font-family: ${fontRoboto};
  line-height: 28px;
  text-align: center;
`;

type MonthContainerProps = {
  $isKeyEvent?: boolean;
};

export const MonthContainer = styled.div<MonthContainerProps>`
  color: ${props => (props.$isKeyEvent ? "#4585FF" : "#000000")};
  font-weight: 400;
  font-size: 12px;
  font-family: ${fontRoboto};
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
`;

export const RightContainer = styled.div`
  grid-area: right;
`;

export const DateContainer = styled.div`
  color: #333333;
  font-weight: 700;
  font-size: 14px;
  font-family: ${fontRobotoCondensed};
  line-height: 16px;

  span {
    font-weight: 400;

    :empty {
      display: none;
    }

    :before {
      content: " | ";

      margin-inline: 8px;

      color: #333333;
      font-weight: 400;
      font-size: 14px;
      font-family: ${fontRobotoCondensed};
    }
  }
`;

export const TimeContainer = styled.div``;

export const LocationContainer = styled.div`
  margin-block-start: 4px;

  color: #333333;
  font-weight: 400;
  font-size: 14px;
  font-family: ${fontRobotoCondensed};
  line-height: 16px;
`;

export const StyledArrowForwardIcon = styled(ArrowForwardIcon)`
  grid-area: link;
`;

export const EventDescription = styled.span`
  grid-column: 1 / 5;
  grid-row-start: 2;

  display: -webkit-box;
  gap: 0;

  padding-block-start: 12px;
  padding-inline: 48px 36px;

  color: #333333;
  font-weight: 400;
  font-size: 14px;
  font-family: ${fontRobotoCondensed};
  line-height: 16.41px;
  white-space: pre-line;
  text-align: start;
  text-overflow: ellipsis;
  word-wrap: break-word;

  opacity: 0.8;

  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export const UpdatedLabelContainer = styled.div`
  margin-block-start: 8px;
`;
