//updatedLabel component

import type { FunctionComponent } from "react";

import { LabelContainer } from "./styles";

type Props = {
  className?: string;
};

export const UpdatedLabel: FunctionComponent<Props> = ({ className }) => (
  <LabelContainer className={className}>updated</LabelContainer>
);

UpdatedLabel.displayName = "UpdatedLabel";
